import gql from 'graphql-tag';

export const GET_ALL_RULES = gql`
  query getAllRules($teamId: String!, $now: String!) {
    getAllRules: getRulesByTeamId(teamId: $teamId, now: $now) {
      _id
      ruleId
      arStatus
      ruleName
      ruleType
      arFor {
        forType
        arFrom {
          fromType
          orgId
          fromValues {
            fromValName
            fromValId
            adamId
          }
          campaignId
        }
      }
      arActions {
        actionLimit
        actionList {
          aiType
          aiOperation
          aiOperators
          aiAddTo
          aiCPTBidType
          aiCPTBidValue
          aiValue
          aiEmail
          aiSlack
          aiCampaigns {
            addToName
            addToId
          }
          aiAdgroups {
            addToName
            addToId
          }
          aiMatchType
        }
      }
      createdOn
      arConditions {
        ciField
        ciOperation
        ciOperator
        ciFromValue
        ciFromValue
        ciToValue
        ciValue
      }
      arConditionSettings {
        ciTimeRange
        ciCustomHour
        ciAndOr
      }
      arSettings {
        checkFrequency
        excludeDays
        customHour
      }
    }
  }
`;

export const GET_ALL_LOGS = gql`
  query getAlllogs($teamId: String!, $now: String!) {
    getAlllogs: getLogsByTeamId(teamId: $teamId, now: $now) {
      _id
      logId
      ruleName
      logStatus
      createdOn
      updatedOn
      ruleFreq
      logId
      ruleId
      data
      runLogs
    }
  }
`;

export const DISABLE_AR_RULE = gql`
  query disableARRule($ruleId: String!) {
    disableARRule: disableRule(ruleId: $ruleId) {
      successMessage
      code
    }
  }
`;

export const ENABLE_AR_RULE = gql`
  query enableARRule($ruleId: String!) {
    enableARRule: enableRule(ruleId: $ruleId) {
      successMessage
      code
    }
  }
`;

export const DELETE_AR_RULE = gql`
  query deleteARRule($ruleId: String!) {
    deleteARRule: deleteRule(ruleId: $ruleId) {
      successMessage
      code
    }
  }
`;

export const CREATE_AR_RULE = gql`
  mutation($ruleReq: AutomationRule!) {
    createARRule: createRule(ruleReq: $ruleReq) {
      successMessage
      code
    }
  }
`;

export const UPDATE_AR_RULE = gql`
  query($ruleId: String!, $ruleReq: AutomationRule!) {
    updateARRule: updateRule(ruleId: $ruleId, ruleReq: $ruleReq) {
      successMessage
      code
    }
  }
`;

export const GET_SLACKS = gql`
  query {
    getSlacks: getSlackListForTeam {
      IntegrationID
      channel
    }
  }
`;

export const GET_EMAILS = gql`
  query {
    getEmails: getEmailListForTeam {
      Email
    }
  }
`;

export const CREATE_AR_ADV_RULE = gql`
  mutation($ruleReq: AdvanceAutomationRule!) {
    createARRule: createARRule(ruleReq: $ruleReq) {
      successMessage
      code
    }
  }
`;

export const UPDATE_AR_ADV_RULE = gql`
  query($ruleId: String!, $ruleReq: AdvanceAutomationRule!) {
    updateARRule: updateARRule(ruleId: $ruleId, ruleReq: $ruleReq) {
      successMessage
      code
    }
  }
`;

export const GET_ALL_RULES_ADV = gql`
  query getAdvAllRules($teamId: String!, $now: String!) {
    getAdvAllRules: getAdvRulesByTeamId(teamId: $teamId, now: $now) {
      _id
      ruleId
      arStatus
      ruleName
      settings {
        ruleSettings {
          checkFrequency
          isIndefinite
        }
      }
      createdOn
    }
  }
`;

export const GET_SINGLE_RULE_ADV = gql`
  query getAdvSingleRules($ruleId: String!, $now: String!) {
    getAdvSingleRules: getAdvRuleByRuleId(ruleId: $ruleId, now: $now) {
      _id
      ruleId
      query
      sources {
        fromType
        forType
        andOrType
        from {
          name
          orgId
          campaignId
          adamId
          adgroupId
          keywordId
        }
        conditions {
          fieldpath
          operation
          operator
          fromValue
          toValue
          value
          goalId
        }
      }
      ruleName
      actions {
        actionId
        copy
        target {
          fromType
          forType
          from {
            name
            orgId
            campaignId
            adamId
            adgroupId
            keywordId
          }
        }
        action {
          type
          aiOperation
          aiOperators
          aiAddTo
          aiCPTBidType
          aiCPTBidValue
          aiValue
          aiEmail
          aiMatchType
          aiSlack
        }
      }
      settings {
        conditionSettings {
          timeRange
          andOr
          dateRange
        }
        ruleSettings {
          checkFrequency
          isDated
          dateRange
          isIndefinite
        }
      }
    }
  }
`;
export const DISABLE_AR_ADV_RULE = gql`
  query disableARRule($ruleId: String!) {
    disableARRule: disableARRule(ruleId: $ruleId) {
      successMessage
      code
    }
  }
`;

export const REACTIVATE_SINGLE_RULE_ADV = gql`
  query reactivateARRule($ruleId: String!) {
    reactivateARRule: reactivateARRule(ruleId: $ruleId) {
      successMessage
      code
    }
  }
`;

export const ENABLE_AR_ADV_RULE = gql`
  query enableARRule($ruleId: String!) {
    enableARRule: enableARRule(ruleId: $ruleId) {
      successMessage
      code
    }
  }
`;

export const DELETE_AR_ADV_RULE = gql`
  query deleteARRule($ruleId: String!) {
    deleteARRule: deleteARRule(ruleId: $ruleId) {
      successMessage
      code
    }
  }
`;

export const AR_ASA_SNAPSHOT = gql`
  query arAsaSnapshot {
    arAsaSnapshot {
      campaigns {
        id
        name
        state
        budget
        dailyBudget
        cpa
        cpt
        spend
        impressions
        taps
        installs
        ttr
        conversionRate
        adgroups {
          name
          id
          state
          cpa
          cpt
          spend
          impressions
          taps
          installs
          ttr
          conversionRate
          keywords {
            name
            id
            state
            cpa
            cpt
            spend
            impressions
            taps
            installs
            ttr
            conversionRate
          }
        }
      }
    }
  }
`;

export const TEST_RUN_SCRIPTED_AR = gql`
  query testRun($data: String!) {
    testRun(data: $data) {
      successMessage
      data
    }
  }
`;

export const SAVE_SCRIPTED_AR = gql`
  query saveSAR($data: String!, $name: String!, $ruleId: String!) {
    saveSAR(data: $data, name: $name, ruleId: $ruleId) {
      successMessage
      data
    }
  }
`;

export const GET_ALL_RULES_SCRIPTED = gql`
  query getAllScriptedRules($now: Float!) {
    getAllScriptedRules: getAllScriptedRules(now: $now) {
      _id
      ruleId
      status
      disable
      ruleName
      createdOn
      goals
      automationSteps
      selectShareVoiceOption
      settings {
        checkFrequency
        isIndefinite
        dateRange
        isDated
        emailIds
        slackIds
        sendReports
        lookback
        activeEntitiesOnly
      }
      lastRun
      runEvery
      nextRunAt
      superGlobals
      inProcess
    }
  }
`;

export const GET_SAR_LOG = gql`
  query getSARLog($now: Float!, $ruleId: String!) {
    getSARLog: getSARLog(now: $now, ruleId: $ruleId) {
      successMessage
      data
    }
  }
`;

export const DELETE_SAR = gql`
  query deleteSAR($ruleId: String!, $now: String!) {
    deleteSAR(ruleId: $ruleId, now: $now) {
      successMessage
      data
    }
  }
`;

export const TOGGLE_SAR = gql`
  query toggleSAR($ruleId: String!, $action: Boolean!, $now: String!) {
    toggleSAR(ruleId: $ruleId, action: $action, now: $now) {
      successMessage
      data
    }
  }
`;

export const UPDATE_SCRIPTED_AR = gql`
  query updateSAR($data: String!, $name: String!, $ruleId: String!) {
    updateSAR(data: $data, name: $name, ruleId: $ruleId) {
      successMessage
      data
    }
  }
`;

export const GET_MYSQL_COUNT = gql`
  query {
    getMySQLCount {
      successMessage
      data
      code
    }
  }
`;

export const GET_SAR_Metrics = gql`
  query getSARMetrics($now: Float!, $filters: String!) {
    getSARMetrics(now: $now, filters: $filters) {
      date_mappable
      rule_run
      rule_tested
      rule_created
      rule_updated
      rule_run_with_goal
      campaign_paused
      adgroup_paused
      keyword_paused
      keyword_bid_increased
      keyword_bid_decreased
      campaign_budget_increased
      campaign_budget_decreased
      campaign_resumed
      adgroup_resumed
      keyword_resumed
      keyword_moved
      keyword_copied
      report_sent
      email_sent
      email_report_sent
      slack_report_sent
      cpa
      cpg
      spend
      installs
      taps
      impressions
      goal_count
      revenue
      keywords
      roas
      cpt
    }
  }
`;

export const GET_ALL_RULES_SCRIPTED_FOR_TEAM = gql`
  query getAllScriptedRulesForTeam($now: Float!, $teamId: String!) {
    getAllScriptedRulesForTeam(now: $now, teamId: $teamId) {
      ruleId
      ruleName
    }
  }
`;

export const CREATE_KWJ_RULES = gql`
  query createKeywordJourneyRules($data: String!, $at: Float!) {
    createKeywordJourneyRules(data: $data, at: $at) {
      successMessage
    }
  }
`;

export const CREATE_STL_RULES = gql`
  query createStrategicLosingRules($data: String!, $at: Float!) {
    createStrategicLosingRules(data: $data, at: $at) {
      successMessage
      data
    }
  }
`;

export const CREATE_LDW_RULES = gql`
  query createLateDayWinningRules($data: String!, $at: Float!) {
    createLateDayWinningRules(data: $data, at: $at) {
      successMessage
      data
    }
  }
`;

export const QUEUE_SAR_LIVE_RUN = gql`
  query liveRunCloud($at: Float!, $data: String!) {
    liveRunCloud: liveRunCloud(at: $at, data: $data) {
      successMessage
      data
    }
  }
`;

export const QUEUE_SAR_TEST_RUN = gql`
  query testRunCloud($at: Float!, $data: String!) {
    testRunCloud: testRunCloud(at: $at, data: $data) {
      successMessage
      data
    }
  }
`;
